import { useCallback, useState } from 'react';
import { ApiError, OpenAPI } from 'app/API';

type TCallApiOptions = {
  onError?: (error: any) => void;
  onSuccess?: (response: any) => void;
  dontCatchErrors?: boolean;
};

export function useAPI() {
  const [error, setError] = useState<ApiError | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const API_URL = import.meta.env.VITE_API_URL ?? '';

  OpenAPI.BASE = API_URL ?? '';
  OpenAPI.WITH_CREDENTIALS = true;

  const call = useCallback(async function (request: any, options?: TCallApiOptions) {
    setIsLoading(true);
    try {
      const response = await request;
      setError(undefined);
      if (options?.dontCatchErrors) {
        if (options?.onSuccess) {
          options.onSuccess(response.body);
        }
        return response.body;
      }

      if (response.status === 500) {
        console.error('response.body', response.body);
        console.error('response.url', response.url);
        console.error('response.status', response.status);
        console.error('response', response);
        window.location.href = '/errors/500';
        return {};
      }

      if (response.status >= 402) {
        console.error('response.body', response.body);
        console.error('response.url', response.url);
        console.error('response.status', response.status);
        console.error('response', response);
        setError(error);
        if (options?.onError) {
          options.onError(response.body.message);
        }
        return {};
      }
      if (options?.onSuccess) {
        options.onSuccess(response.body);
      }
      return response.body;
    } catch (error: any) {
      setError(error);
      if (options?.onError) {
        options.onError(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  function dismissError() {
    setError(undefined);
  }

  return { dismissError, error, isLoading, call };
}
